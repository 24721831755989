<script >
import DButton from '@/components/elements/d-button.vue';
import iconHome from '@/assets/ic-home.svg';
import { formatStopwatch } from '@/utils/format-stopwatch';
import { mapActions } from 'vuex';
import { ERROR } from '@/constants/list-error';
import { wait } from '@/utils/wait';
import { ENV } from '@/constants/config';
const COUNTDOWN = 30; // seconds

const __sfc_main = {
  name: 'ModalEmailVerification',
  components: {
    DButton
  },
  props: {
    isOpen: {
      type: Boolean
    },
    email: {
      type: String,
      required: true
    },
    retry: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      countDown: COUNTDOWN,
      iconHome
    };
  },

  computed: {
    message() {
      return this.retry ? 'Selamat! link telah dikirim ke email, silahkan cek email Anda untuk melakukan verifikasi email' : 'Selamat! Pendaftaran Anda berhasil, silahkan cek email Anda untuk melakukan verifikasi email';
    }

  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.countDown = COUNTDOWN;
        this.countDownTimer();
      }
    }

  },
  methods: { ...mapActions({
      resendVerification: 'auth/resendVerification'
    }),
    formatStopwatch,

    async resendVerificationEmail() {
      this.countDown = COUNTDOWN;
      this.countDownTimer();
      this.resendVerification({
        email: this.email
      }).then(() => {
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Sukses mengirimkan email verifikasi',
          duration: 3000
        });
      }).catch(async err => {
        if (err?.message === ERROR.EMAIL_ALREADY_VERIFIED) {
          this.$toast({
            status: 'success',
            title: 'Success',
            description: err?.message,
            duration: 3000
          });
          await wait(1000);
          this.$router.push('/');
          return;
        }

        this.$({
          status: 'error',
          title: 'Error',
          description: err?.message,
          duration: 3000
        });
      });
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },

    onClickHomepage() {
      window.location.replace(`${ENV.URL_DIETELA_LANDING_PAGE}`);
    }

  }
};
__sfc_main.components = Object.assign({
  DButton
}, __sfc_main.components);
export default __sfc_main;
</script>
<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    is-centered
    size="2xl"
  >
    <c-modal-content
      ref="content"
      class="modalss"
    >
      <c-modal-body>
        <CImage
          src="/images/success-iso.png"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
        />
        <CHeading
          as="h1"
          color="primary.400"
          font-size="28px"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Cek Email!
        </CHeading>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
          margin-bottom="30px"
        >
          {{ message }}
        </CText>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
        >
          Saya tidak mendapatkan email <span
            v-show="countDown <= 0"
            class="link"
            @click="resendVerificationEmail"
          >Kirim ulang</span> <span
            v-show="countDown > 0"
            class="link-disabled"
          >{{ formatStopwatch(countDown * 1000) }}</span>
        </CText>
        <d-button
          :icon-end="iconHome"
          style="margin-top: 40px"
          @click="onClickHomepage"
        >
          Homepage
        </d-button>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>


<style scoped>
.modalss::v-deep > section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.link {
  display: block;
  color: #008C81;
  cursor: pointer;
}
.link-disabled {
  display: block;
  color: #a2a2a2;
}
.link:hover {
  text-decoration: underline
}

@media only screen and (min-width: 640px) {
  .link, .link-disabled {
    display: inline-block;
    margin-left: 16px;
  }
}
</style>