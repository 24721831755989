var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "is-centered": "",
      "size": "2xl"
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss"
  }, [_c('c-modal-body', [_c('CImage', {
    attrs: {
      "src": "/images/success-iso.png",
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "30px"
    }
  }), _c('CHeading', {
    attrs: {
      "as": "h1",
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600",
      "margin-bottom": "30px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Cek Email! ")]), _c('CText', {
    attrs: {
      "color": "darkGray.900",
      "font-size": "16px",
      "line-hieght": "27px",
      "font-family": "Roboto",
      "margin-bottom": "30px"
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c('CText', {
    attrs: {
      "color": "darkGray.900",
      "font-size": "16px",
      "line-hieght": "27px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Saya tidak mendapatkan email "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countDown <= 0,
      expression: "countDown <= 0"
    }],
    staticClass: "link",
    on: {
      "click": _vm.resendVerificationEmail
    }
  }, [_vm._v("Kirim ulang")]), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countDown > 0,
      expression: "countDown > 0"
    }],
    staticClass: "link-disabled"
  }, [_vm._v(_vm._s(_vm.formatStopwatch(_vm.countDown * 1000)))])]), _c('d-button', {
    staticStyle: {
      "margin-top": "40px"
    },
    attrs: {
      "icon-end": _vm.iconHome
    },
    on: {
      "click": _vm.onClickHomepage
    }
  }, [_vm._v(" Homepage ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }